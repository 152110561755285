export const staking_list = [
  {
    "id": 0,
    "symbol": "STAR",
    "address": "0x50907C3CA956c522894f3df422C39ab3c7c24A45",
    "poolId" : 0,
  },
  // {
  //   "id": 1,
  //   "symbol": "DOT",
  //   "address": "0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF",
  //   "poolId" : 9,
  // }
]
