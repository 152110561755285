import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import Web3 from "web3/lib"
import "vue-toastification/dist/index.css";
import VueLazyload from "vue-lazyload";
import './quasar'
import vClickOutside from 'v-click-outside'

// vue router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
  });

const loadimage = require("@/assets/placeholder.jpg");
const errorimage = require("@/assets/placeholder.jpg");
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 2,
  });

Vue.use(vClickOutside)

new Vue({
    router,
    store,
    beforeCreate() {
      const options = {
        keepAlive: true,
        timeout: 20000, // milliseconds,
        headers: [{name: 'Access-Control-Allow-Origin', value: '*'}],
        withCredentials: false,
      };

      const httpProvider = new Web3.providers
              .HttpProvider("https://rpc.astar.network:8545", options);
      window.provider = httpProvider;
      window.web3 = new Web3(httpProvider);

      store.commit('init')
      store.commit('initProc')
      store.commit('read_globalInfo')
      store.commit('read_liquidity')
      store.commit('read_starCardsNFT')
      store.commit('read_skywalkersNFT')
      store.commit('read_nftStaking')
      store.commit('read_liquiditygenerator')
      store.commit('read_exchanger')
      store.commit('read_lottery')
      store.commit('read_treasury')
      store.commit('read_starCardsGovernance')

      setInterval(()=>{
        store.commit('read_globalInfo')
        store.commit('read_starCardsNFT')
        store.commit('read_skywalkersNFT')
        store.commit('read_liquidity')
        store.commit('read_masterchef')
        store.commit('read_staking')
        store.commit('read_nftStaking')
        store.commit('read_liquiditygenerator')
        store.commit('read_exchanger')
        store.commit('read_lottery')
        store.commit('read_treasury')
        store.commit('read_starCardsGovernance')
      }, 10000)
    },
    render: h => h(App),
}).$mount('#app')