<template>
  <div id="app">
    <div v-if="loading" class="overlay">
        <div class="loading" v-click-outside="onClickOutside">
          <div class="content">
            <div class="waiting text-center">
              <img :src="loadimage" />Please wait while your transaction is being confirmed.
            </div>
            <div class="text-center">
               <small><a :href="getBlockExplorer"  target="_blank">{{getTransactionHash}}</a></small>
            </div>
          </div>
        </div>
    </div>
     <router-view/>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import {
  registerAccountChange,
  registerNetworkChange,
} from './metamask-utils'
export default {
  name: 'App',
  data: () => ({
    loadimage: require("@/assets/images/hourglass.gif"),
  }),
  components: {
  },
  mounted () {
  },
  computed: {
    loading() {
      return this.$store.state.global.loading;
    },
    getTransactionHash() {
      return this.$store.state.global.transactionHash;
    },
    getBlockExplorer() {
        return "https://blockscout.com/astar/tx/" + this.$store.state.global.transactionHash;
    },
  },
  methods: {
    onClickOutside (event) {
      this.$store.dispatch("hideLoadingWindow");
    },
  }
}
</script>
<style>
    .loading a {
        color:#abc4ed88;
    }

    .loading a:hover {
        color: rgb(247, 238, 157, 0.8) !important;
        text-decoration: none !important;
    }
</style>