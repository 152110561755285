export const token_list = [
  {
    "id": 0,
    "name": "ASTR",
    "symbol": "ASTR",
    "address": "0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720",
    "decimals": 18,
    "coingeckoid": "astar",
    "coingeckochartid": "22617",
    "logo": require('@/assets/images/tokens/astr.png'),
    "price": 0
  },
  {
    "id": 1,
    "name": "Wrapped Astar",
    "symbol": "WASTR",
    "address": "0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720",
    "decimals": 18,
    "coingeckoid": "astar",
    "coingeckochartid": "22617",
    "logo": require('@/assets/images/tokens/wastr.png'),
    "price": 0
  },
  {
    "id": 2,
    "name": "StarSwap Token",
    "symbol": "STAR",
    "address": "0x50907C3CA956c522894f3df422C39ab3c7c24A45",
    "decimals": 18,
    "coingeckoid": "",
    "coingeckochartid": "825",
    "logo": require('@/assets/images/tokens/star.png'),
    "price": 0
  },    
  {
    "id": 3,
    "name": "USD Coin",
    "symbol": "USDC",
    "decimals": 6,
    "address": "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98",
    "coingeckoid": "usd-coin",
    "coingeckochartid": "6319",
    "logo": require('@/assets/images/tokens/usdc.webp'),
    "price": 0
  },
  {
    "id": 4,
    "name": "Tether USD",
    "symbol": "USDT",
    "decimals": 6,
    "address": "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
    "coingeckoid": "tether",
    "coingeckochartid": "325",
    "logo": require('@/assets/images/tokens/usdt.webp'),
    "price": 0
  },
  {
    "id": 5,
    "name": "Polkadot",
    "symbol": "DOT",
    "address": "0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF",
    "decimals": 10,
    "coingeckoid": "polkadot",
    "coingeckochartid": "12171",
    "logo": require('@/assets/images/tokens/polkadot.png'),
    "price": 0
  },
  {
    "id": 6,
    "name": "Dai Stablecoin",
    "symbol": "DAI",
    "address": "0x6De33698e9e9b787e09d3Bd7771ef63557E148bb",
    "decimals": 18,
    "coingeckoid": "dai",
    "coingeckochartid": "9956",
    "logo": require('@/assets/images/tokens/dai.webp'),
    "price": 0
  },
  /*{
    "id": 6,
    "name": "ArthSwap Token",
    "symbol": "ARSW",
    "address": "0xDe2578Edec4669BA7F41c5d5D2386300bcEA4678",
    "decimals": 18,
    "coingeckoid": "",
    "coingeckochartid": "",
    "logo": require('@/assets/images/tokens/arsw.png'),
    "price": 0
  },*/
  {
    "id": 7,
    "name": "Wrapped Ether",
    "symbol": "WETH",
    "address": "0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c",
    "decimals": 18,
    "coingeckoid": "ethereum",
    "coingeckochartid": "279",
    "logo": require('@/assets/images/tokens/weth.webp'),
    "price": 0
  },
  {
    "id": 8,
    "name": "Wrapped BTC",
    "symbol": "WBTC",
    "address": "0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA",
    "decimals": 8,
    "coingeckoid": "bitcoin",
    "coingeckochartid": "825",
    "logo": require('@/assets/images/tokens/bitcoin.webp'),
    "price": 0
  },
  {
    "id": 9,
    "name": "Binance USD",
    "symbol": "BUSD",
    "address": "0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E",
    "decimals": 18,
    "coingeckoid": "binance-usd",
    "coingeckochartid": "9576",
    "logo": require('@/assets/images/tokens/busd.webp'),
    "price": 0
  },
  {
    "id": 10,
    "name": "Binance Coin",
    "symbol": "BNB",
    "address": "0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52",
    "decimals": 18,
    "coingeckoid": "binancecoin",
    "coingeckochartid": "825",
    "logo": require('@/assets/images/tokens/bnb.webp'),
    "price": 0
  },
  {
    "id": 11,
    "name": "Shiden Network",
    "symbol": "SDN",
    "address": "0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4",
    "decimals": 18,
    "coingeckoid": "shiden",
    "coingeckochartid": "18027",
    "logo": require('@/assets/images/tokens/shiden.webp'),
    "price": 0
  }
]
