import VueRouter from 'vue-router'
const routes = [
    {
        path: '/',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            component: () =>
                import ('../views/swap.vue')
        }]
    },
    {
        path: '/swap/:token0/:token1',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'swap',
            component: () =>
                import ('../views/swap.vue')
        }]
    },
    {
        path: '/swap',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'swap1',
            component: () =>
                import ('../views/swap.vue')
        }]
    },
    {
        path: '/liquidity/:tabid/:token0/:token1',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'liquidity',
            component: () =>
                import ('../views/liquidity.vue')
        }]
    },
    {
        path: '/liquidity',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'liquidity1',
            component: () =>
                import ('../views/liquidity.vue')
        }]
    },
    {
        path: '/pools',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'pools',
            component: () =>
                import ('../views/pools.vue')
        }]
    },
    {
        path: '/staking',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'staking',
            component: () =>
                import ('../views/staking.vue')
        }]
    },
    {
        path: '/farms',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'farms',
            component: () =>
                import ('../views/farms.vue')
        }]
    },
    {
        path: '/starcards',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'starcards',
            component: () =>
                import ('../views/starcards.vue')
        }]
    },
    {
        path: '/holders',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'view',
            component: () =>
                import ('../views/view.vue')
        }]
    },
    {
        path: '/skywalkers',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'skywalkers',
            component: () =>
                import ('../views/skywalkers.vue')
        }]
    },
    {
        path: '/viewskywalker',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'skywalkersView',
            component: () =>
                import ('../views/viewskywalker.vue')
        }]
    },
    {
        path: '/nftStaking',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'nftStaking',
            component: () =>
                import ('../views/nftStaking.vue')
        }]
    },
    {
        path: '/private',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'private',
            component: () =>
                import ('../views/Exchanger.vue')
        }]
    },
    {
        path: '/privateEx',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'privateEx',
            component: () =>
                import ('../views/ExchangerEx.vue')
        }]
    },
    {
        path: '/launch',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'launch',
            component: () =>
                import ('../views/launch.vue')
        }]
    },
    {
        path: '/StarBall',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'lotto',
            component: () =>
                import ('../views/starBall.vue')
        }]
    },
    {
        path: '/results',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'results',
            component: () =>
                import ('../views/results.vue')
        }]
    },
    {
        path: '/treasury',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'treasury',
            component: () =>
                import ('../views/Treasury.vue')
        }]
    },
    {
        path: '/info',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'info',
            component: () =>
                import ('../views/info.vue')
        }]
    },
    {
        path: '/vote',
        component: () =>
            import ('../components/layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            name: 'vote',
            component: () =>
                import ('../views/starSwapGovernance.vue')
        }]
    },
    {
        path: '/api/circulating-supply',
        component: () =>
            import ('../components/layouts/empty.vue'),
        children: [{
            path: '/',
            name: 'circulating-supply',
            component: () =>
                import ('../views/circulating-supply.vue')
        }]
    },
    {
        path: '/api/24hr',
        component: () =>
            import ('../components/layouts/empty.vue'),
        children: [{
            path: '/',
            name: '24hr',
            component: () =>
                import ('../views/24hr.vue')
        }]
    },
    {
        path: '/api/pairs',
        component: () =>
            import ('../components/layouts/empty.vue'),
        children: [{
            path: '/',
            name: 'pairs',
            component: () =>
                import ('../views/pairs.vue')
        }]
    },
    {
        path: '/api/summary',
        component: () =>
            import ('../components/layouts/empty.vue'),
        children: [{
            path: '/',
            name: 'summary',
            component: () =>
                import ('../views/summary.vue')
        }]
    },
    {
        path: '/api/cmc',
        component: () =>
            import ('../components/layouts/empty.vue'),
        children: [{
            path: '/',
            name: 'cmc',
            component: () =>
                import ('../views/cmc.vue')
        }]
    },
]
const router = new VueRouter({
    mode: 'history',
    routes
})
export default router
