export const whiteList = [
  "0x1c62e6f7dD3BD5ef0999080056c289d600Ee31Ec",
  "0xBdc0BF85eF02C7027Ca0a7BAd7A89fF1BB60c204",
  "0x0B088013AF811D1B8CF4EdBA607d13A61B2E7eE2",
  "0xBAfb0cd28aBEeb8359D1689e6691D1704271b20e",
  "0xe7Dd6294343Af0804860b9B97A958981A37C7ea4",
  "0x10b6795AD572B07b92dC9b005d02f9DC04997B3d",
  "0x2C4C168A2fE4CaB8E32d1B2A119d4Aa8BdA377e7",
  "0x91A68719a38B229891AAa4C964aC7B6a8c4E7C4f",
  "0xFC242f42127494934efc79386ec03DCc8991d095"
  ]